<template>
  <div
    id="div-with-loading"
    class="container-fluid mb-4"
  >

    <b-card class="mx-0">
      <div class="d-flex flex-wrap mx-auto">
        <b-col
          cols="12"
          md="4"
          class="my-2"
        >
          <h6 class="">
            Agency Name
          </h6>
          <b-form-input
            v-model="select_name"
            class="w-full"
            placeholder="Start typing the agency's name"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="my-2"
        >
          <h6 class="">
            Industry
          </h6>
          <v-select
            v-model="select_tags"
            placeholder="Select your Preferred industry"
            multiple
            :options="categories"
            label="value"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="my-2"
        >
          <h6 class="">
            Rating
          </h6>
          <v-select
            v-model="select_rating"
            placeholder="Specify the company rating"
            :options="ratings"
            label="value"
          />
        </b-col>
      </div>
    </b-card>

    <b-row class="my-0 mx-auto w-100">
      <hr>
      <h4
        v-if="events_data.length < 1"
        class="grey mx-auto text-center mt-1"
      >
        No data available for now
      </h4>
      <b-row class="w-100 mt-8 mx-1">
        <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
        <!-- .slice(i * 4, (i + 1) * 4) -->

        <b-col
          v-for="(item, index) in events_data"
          :key="index"
          cols="12"
          md="6"
          class="w-100  mb-base "
        >
          <b-card class="container p-2">
            <div class="d-flex flex-wrap justify-content-between">
              <div class="">
                <b-row class="ml-1">
                  <h5 class="font-weight-bolder">
                    {{ item.ea_name }}
                  </h5>
                  <!-- <b-badge pill
                    :key="index"
                    v-for="(text, index) in item.preferences"
                    variant="warning"
                    class="h-4 mt-2 ml-4"
                    >{{ capitalizeFirstLetter(text) }}</b-badge
                  >-->
                </b-row>

                <b-row class="">
                  <b-badge
                    v-if="item.verified"
                    pill
                    color="dark"
                    class="h-4"
                  >✔️ Verified by HeadHunters HQ</b-badge>
                  <b-badge
                    v-if="item.suggested"
                    pill
                    variant="warning"
                    class="h-4 ml-1"
                  >Suggested</b-badge>
                </b-row>

                <b-row
                  v-if="item.contact_name"
                  class="mt-1 self-start"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="cursor-pointer ml-1"
                  />
                  <h6 class="mt-1">
                    Company contact name: {{ item.contact_name }}
                  </h6>
                </b-row>
                <b-row
                  v-if="item.contact_number"
                  class="mt-1 self-start"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="cursor-pointer ml-1"
                  />
                  <h6 class="mt-1">
                    Company contact number: {{ item.contact_number }}
                  </h6>
                </b-row>

                <b-row class="ml-1 mt-1">
                  <feather-icon
                    icon="ChevronRightIcon"
                    class=""
                  />
                  <h6
                    v-if="item.ratings === 'N.A.'"
                    class=""
                  >Customer Service Rating (Data from Ministry of Manpower):
                    {{ item.ratings }}
                  </h6>
                  <h6
                    v-else
                    class=""
                  >Customer Service Rating (Data from Ministry of Manpower):
                    {{ item.ratings }} stars
                  </h6>
                </b-row>
                <b-row
                  v-if="!item.private"
                  class="mt-1 ml-1 self-start"
                >
                  <!-- <feather-icon
                    icon="LayersIcon"
                    class="cursor-pointer"
                  ></feather-icon> -->
                  <!-- <h5 class="ml-2 mt-1 text-primary">
                    {{ item.company.toUpperCase() }}
                  </h5> -->
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="cursor-pointer"
                  />
                  <h6 class="">
                    Active since {{ item.licence_date }}
                  </h6>
                </b-row>
              </div>

              <div class="flex self-center">
                <b-button
                  class="mr-2 ml-2 h-12 shadow-lg"
                  variant="primary"
                >Reach out!</b-button>
              </div>

            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-row>

    <b-row class="mx-auto">
      <b-button
        v-if="events_data.length >= default_count"
        class="shadow-lg mx-auto"
        variant="warning"
        @click="viewMore"
      >View more agencies</b-button>
    </b-row>
    <hr>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
  },
  data() {
    return {
      job_link: '',
      not_fullname: '',
      not_email: '',
      not_position: '',
      not_industry: '',
      event_id: '',
      fullname: '',
      email: '',
      resume_uploaded: false,
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      select_tags: [],
      select_name: '',
      all_positions: [],
      select_rating: '',
      current_user: '',
      position: '',
      description: '',
      job_count: 0,
      default_count: 20,
      options3: [
        { text: 'IT', value: 0 },
        { text: 'Blade Runner', value: 2 },
        { text: 'Thor Ragnarok', value: 3 },
      ],
      ratings: [
        { text: 'Not specified', value: 0 },
        { text: '⭐️ ', value: 1 },
        { text: '⭐️ ⭐️ ', value: 2 },
        { text: '⭐️ ⭐️ ⭐️ ', value: 3 },
        { text: '⭐️ ⭐️ ⭐️ ⭐️ ', value: 4 },
        { text: '⭐️ ⭐️ ⭐️ ⭐️ ⭐️ ', value: 5 },
      ],
      categories: [
        {
          text: 'Accommodation and food service activities',
          value: 'Accommodation and food service activities',
        },
        {
          text: 'Administrative and support service activities',
          value: 'Administrative and support service activities',
        },
        { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
        {
          text: 'Arts, entertainment and recreation',
          value: 'Arts, entertainment and recreation',
        },
        { text: 'Construction', value: 'Construction' },
        { text: 'Education', value: 'Education' },
        {
          text: 'Electricity,gas,steam and air-conditioning supply',
          value: 'Electricity,gas,steam and air-conditioning supply',
        },
        {
          text: 'Financial and insurance activities',
          value: 'Financial and insurance activities',
        },
        {
          text: 'Health and social services',
          value: 'Health and social services',
        },
        {
          text: 'Information and communications',
          value: 'Information and communications',
        },
        { text: 'Manufacturing', value: 'Manufacturing' },
        { text: 'Mining and quarrying', value: 'Mining and quarrying' },
        {
          text: 'Other service activities',
          value: 'Other service activities',
        },
        {
          text: 'Professional, scientific and technical activities',
          value: 'Professional, scientific and technical activities',
        },
        {
          text: 'Public administration and defence',
          value: 'Public administration and defence',
        },
        { text: 'Real estate activities', value: 'Real estate activities' },
        {
          text: 'Transportation and storage',
          value: 'Transportation and storage',
        },
        {
          text: 'Water supply;sewerage waste management and remediation activities',
          value:
            'Water supply;sewerage waste management and remediation activities',
        },
        {
          text: 'Wholesale and retail trade',
          value: 'Wholesale and retail trade',
        },
      ],
      dates: [],
      isLoading: true,
    }
  },
  computed: {
    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys())
    },
  },
  watch: {
    select_tags: {
      handler(val, oldVal) {
        this.filterAgencies()
      },
    },
    select_name: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.filter_events();
      },
    },
    select_date: {
      handler(val, oldVal) {
        // this.getEvents();
        // this.filter_events();
      },
    },
    select_rating: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.getEvents();
        // this.filter_events();
      },
    },
    deep: true,
  },

  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
  },

  created() {
    const { token } = this.$store.state.auth.ActiveUser
    this.email_address = this.$store.state.auth.ActiveUser.email
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    try {
      this.resume_uploaded = this.$store.state.auth.ActiveUser.resume_upload
    } catch (error) {}

    this.getEvents()
  },
  methods: {
    onCopy(jobid) {
      this.copyTextToClipboard(`${window.location.origin}/jobs/${jobid}`)
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Success',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },
    onError() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Failed',
            icon: 'AlertCircleIcon',
            text: 'Error in copying text',
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    check_empty(value) {
      if (value) {
        if (value.length > 4) {
          return true
        }
        return false
      }
      return true
    },
    send_response() {
      if (
        this.check_empty(this.not_fullname)
        && this.check_empty(this.not_email)
        && this.check_empty(this.not_position)
      ) {
        const all_data = {
          fullname: this.not_fullname,
          email: this.not_email,
          position: this.not_position,
          industry: this.not_industry,
        }
        this.$http
          .post('/api/response', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Response sent',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.not_position = ''
              this.not_fullname = ''
              this.not_email = ''
              this.not_industry = ''
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Response Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Response Failed',
              icon: 'AlertCircleIcon',
              text: 'Please fill all form values correctly.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    joinEvent(item, event) {
      event.target.innerText = 'Applied'
      const { email } = this.$store.state.auth.ActiveUser
      const { fullname } = this.$store.state.auth.ActiveUser

      const all_data = {
        job_id: item._id,
        email,
        fullname,
        approved: true,
      }

      if (!email) {
        this.event_id = item._id
        this.popupReg = true
      } else {
        this.$http
          .post('/api/apply', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Application successful',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              ) // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Application Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      }
    },

    viewMore() {
      this.default_count *= 2
      this.events_data = this.events_part_data.slice(0, this.default_count + 1)
      // this.$vs.close();
    },

    filterAgencies() {
      // this.default_count *= 2
      // this.events_data = this.events_part_data.slice(0, (this.default_count) + 1);
      // this.$vs.close();
      const output = []

      for (const item of this.events_part_data) {
        let push_name = false
        let push_rating = false
        let push_tags = false
        const checker = (arr, target) => target.some(v => arr.includes(v))
        if (this.select_name) {
          if (item.ea_name.includes(this.select_name.toUpperCase())) {
            push_name = true
          }
        }

        if (this.select_tags.length > 0) {
          if (checker(this.selected_tags, item.company_industry)) {
            push_tags = true
          }
        }
        if (this.select_rating) {
          if (this.select_rating === 0) {
            push_rating = true
          } else if (parseFloat(item.ratings) >= this.select_rating) {
            push_rating = true
          }
        }
        if (this.select_name && this.select_rating) {
          if (push_name && push_rating) {
            output.push(item)
          }
        } else if (this.select_name || this.select_rating) {
          if (push_name || push_rating) {
            output.push(item)
          }
        } else if (this.select_name && this.selected_tags) {
          if (push_name && push_tags) {
            output.push(item)
          }
        } else if (this.select_name || this.selected_tags) {
          if (push_name || push_tags) {
            output.push(item)
          }
        } else {
          output.push(item)
        }
      }

      this.events_data = output.slice(0, this.default_count + 1)
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // let selected_tags = [];
      // for (let data of this.categories) {
      //   for (let categ of this.select_tags) {
      //     if (data.value === categ) {
      //       selected_tags.push(data.text);
      //     }
      //   }
      // }

      // let selected_companies = [];
      // for (let data of this.companies) {
      //   for (let categ of this.select_company) {
      //     if (data.value === categ) {
      //       selected_companies.push(data.text);
      //     }
      //   }
      // }

      let new_events = false
      if (data.ea_name.includes(this.select_name)) {
        selected_date = data.text
        new_events = true
      }

      // let new_events = false;

      // let comp =
      //   selected_companies.length > 0
      //     ? selected_companies.includes(data.company)
      //     : true;
      // let tags =
      //   selected_tags.length > 0
      //     ? selected_tags.some((v) => data.tags.includes(v))
      //     : true;
      // let dates = data.position
      //   .toLowerCase()
      //   .includes(this.select_position.toLowerCase());

      // if (comp && tags && dates) {
      //   new_events = true;
      // }

      return new_events
    },

    selectOne(jobid, all_data) {
      let active_job = {}
      for (const item of all_data) {
        if (item._id === jobid) {
          active_job = item
        }
      }
      if (Object.keys(active_job).length !== 0) {
        this.viewMore(active_job)
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Job details',
              icon: 'AlertCircleIcon',
              text: "It seems this job position has been filled or doesn't exists.",
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    editEvent(item) {
      this.action = 'update'
      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      //   this.select_date = item.date;
      //   this.select_tags = item.tags;
      this.image_url = item.image_url
      this.popupActive = true
    },

    getEvents() {
      this.isLoading = true
      const { email } = this.$store.state.auth.ActiveUser
      this.current_user = email
      this.events_data = []
      this.events_part_data = []
      this.$http
        .get(`/api/agencies/${30}`)
        .then(response => {
          if (response.data.success) {
            for (const item of response.data.message) {
              // let output = this.filter_events(item);
              // if (output) {
              this.events_part_data.push({
                ea_name: item.ea_name,
                licence_date: item.licence_date,
                ratings: item.ratings,
                private: item.private,
                verified: item.verified,
                suggested: item.suggested,
                company_industry: item.company_industry,
                _id: item._id,
              })
              // }
            }
            Array.prototype.subarray = function (start, end) {
              if (!end) {
                end = -1
              }
              return this.slice(start, this.length + 1 - end * -1)
            }
            this.events_data = this.events_part_data.slice(
              0,
              this.default_count + 1,
            )
            this.isLoading = false
          } else {
            this.event_exist = false
            this.isLoading = false
          }
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    updateFiles(input) {
      if (this.email) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Resume upload in progress.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {}
          const fd = new FormData()
          fd.append('file', input.target.files[0])
          delete this.$http.defaults.headers.common.Authorization
          this.$http
            .post(`/api/upload-resume/${this.email}`, fd)
            .then(response => {
              this.resume_uploaded = true
              // let image_url = response.data.data.display_url;
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
            .catch(error => {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: 'Resume upload failed. Try again later.',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
          reader.readAsDataURL(input.target.files[0])
        }
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Please fill the form above to proceed.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
  },
}
</script>

<style lang="scss"></style>
